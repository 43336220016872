<template>
  <div id="app" class="app-body">
    <div class="p-3">
      <div class="columns">
        <div class="column">
          <div class="spacer-small"></div>
          <div class="columns is-vcentered">
            <div class="column pb-5">
              <p class="is-subtitle is-secondary is-bold">Project Workspace</p>
              <p v-if="currentUser" class="mt-5 is-bold">
                Welcome {{ currentUser.fullName }}
              </p>
              <p class="mt-2">
                Access the professional services you need to build your brand
              </p>
            </div>
            <div class="column mt-5 is-3">
              <img src="@/assets/img/brandeeq_method_illustration.svg" />
              <br />
              <router-link :to="{ name: 'BuildBrands' }">
                <b-button
                  type="is-info"
                  size="is-small"
                  class="mt-5 is-button-secondary"
                >
                  Learn more
                </b-button>
              </router-link>
            </div>
          </div>

          <div class="spacer-small"></div>
          <div v-if="loading">
            <Loading />
          </div>
          <div v-if="activeOrders && activeOrders.length && !loading">
            <p class="is-subtitle-small is-dark-gray is-bold my-5">
              Active Services
            </p>

            <div class="columns my-5 is-multiline">
              <div
                v-for="activeOrder in activeOrders"
                :key="activeOrder._id"
                class="column is-6-tablet is-3-desktop mt-5"
                @click="
                  () => {
                    if (activeOrder.status == 'processingPayment') {
                      showPendingPaymentModal = true;
                    }
                  }
                "
              >
                <router-link :to="getActiveOrderLink(activeOrder)">
                  <div class="box hover has-pointer p-0">
                    <div
                      v-if="false"
                      class="has-text-right"
                      style="margin-top: -40px; margin-right: -40px"
                    >
                      <b-tag
                        class="number-tag is-bold"
                        size="is-large"
                        :type="getStatusTagColor(activeOrder.status)"
                        rounded
                      >
                        <i class="mdi mdi-bell"></i>
                      </b-tag>
                    </div>

                    <ServiceIllustrations
                      :service="activeOrder.service.name"
                      :isCard="true"
                    />
                    <div class="px-4 pt-2 pb-4">
                      <div
                        style="margin-top: -28px !important"
                        class="has-text-right"
                      >
                        <b-tag
                          v-if="activeOrder.projectName"
                          :style="
                            activeOrder.projectColor
                              ? getProjectTagStyle(activeOrder.projectColor)
                              : ''
                          "
                          type="is-warning"
                          size="is-small"
                          class="mb-2"
                        >
                          {{ activeOrder.projectName || "Uncategorized" }}
                        </b-tag>
                        <div v-else style="min-height: 30px"></div>
                      </div>
                      <p class="is-subtitle-small is-secondary is-bold">
                        {{ activeOrder.service.name }}
                      </p>
                      <p :class="'' + getStatusClass(activeOrder.status)">
                        {{ activeOrder.status | status("customer") }}
                      </p>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>

            <hr class="my-5" />
          </div>

          <div v-if="completedOrders && completedOrders.length && !loading">
            <p class="is-subtitle-small is-dark-gray is-bold my-5">
              Completed Services
            </p>

            <div class="columns my-5 is-multiline">
              <div
                v-for="completedOrder in completedOrders"
                :key="completedOrder._id"
                class="column is-6-tablet is-3-desktop mt-5"
              >
                <router-link
                  :to="{
                    name: 'OrderDetail',
                    query: { id: completedOrder._id },
                  }"
                >
                  <div class="box hover has-pointer p-0">
                    <div
                      v-if="false"
                      class="has-text-right"
                      style="margin-top: -40px; margin-right: -40px"
                    >
                      <b-tag
                        class="number-tag is-bold"
                        size="is-large"
                        :type="getStatusTagColor(completedOrder.status)"
                        rounded
                      >
                        <i class="mdi mdi-bell"></i>
                      </b-tag>
                    </div>

                    <ServiceIllustrations
                      :service="completedOrder.service.name"
                      :isCard="true"
                    />
                    <div class="px-4 pt-2 pb-4">
                      <div
                        style="margin-top: -28px !important"
                        class="has-text-right"
                      >
                        <b-tag
                          v-if="completedOrder.projectName"
                          :style="
                            completedOrder.projectColor
                              ? getProjectTagStyle(completedOrder.projectColor)
                              : ''
                          "
                          type="is-warning"
                          size="is-small"
                          class="mb-2"
                        >
                          {{ completedOrder.projectName || "Uncategorized" }}
                        </b-tag>
                        <div v-else style="min-height: 30px"></div>
                      </div>
                      <p class="is-subtitle-small is-secondary is-bold">
                        {{ completedOrder.service.name }}
                      </p>
                      <p :class="'' + getStatusClass(completedOrder.status)">
                        {{ completedOrder.status | status("customer") }}
                      </p>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>

            <hr class="my-5" />
          </div>

          <div v-if="allBundles && !loading" class="pt-5">
            <p class="is-subtitle-small is-dark-gray is-bold my-5">
              Discounted Bundles
            </p>

            <div class="columns my-5 is-multiline">
              <div
                class="column is-6-tablet is-4-desktop"
                v-for="product in allBundles"
                :key="product._id"
              >
                <router-link
                  :to="{
                    name: 'ProductDetail',
                    query: { id: product._id },
                  }"
                >
                  <div class="hover has-pointer p-0 m-0">
                    <ServiceIllustrations
                      :service="product.name"
                      :isCard="true"
                    />
                  </div>
                </router-link>
              </div>
            </div>
            <hr class="my-5" />
          </div>

          <div v-if="allProducts && !loading" class="pt-5">
            <p class="is-subtitle-small is-dark-gray is-bold my-5">
              More Services
            </p>

            <div class="columns my-5 is-multiline">
              <div
                class="column is-6-tablet is-3-desktop"
                v-for="product in allProducts"
                :key="product._id"
              >
                <router-link
                  :to="{
                    name: 'ProductDetail',
                    query: { id: product._id },
                  }"
                >
                  <div class="box hover has-pointer service-card p-0">
                    <ServiceIllustrations
                      :service="product.name"
                      :isCard="true"
                    />
                    <div class="px-4 pt-2 pb-4">
                      <p class="is-subtitle-small is-secondary is-bold">
                        {{ product.name }}
                      </p>
                      <p class="text-small mt-3">
                        {{ product.description | limitTo(70) }}
                      </p>
                      <div class="columns is-vcentered mt-2">
                        <!-- <div class="column">
                        <p class="text-small">Starting from</p>
                        <p class="text-medium">
                          {{ service.price | currency }}
                        </p>
                      </div> -->
                        <div class="column is-narrow">
                          <b-button type="is-info" size="is-small">
                            Select
                          </b-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
              <div class="column is-6-tablet is-3-desktop">
                <router-link
                  :to="{
                    name: 'CustomizedService',
                  }"
                >
                  <div class="box hover has-pointer service-card p-0">
                    <ServiceIllustrations
                      :service="'Customized Service'"
                      :isCard="true"
                    />
                    <div class="px-4 pt-2 pb-4">
                      <p class="is-subtitle-small is-bold is-secondary">
                        Customized Service
                      </p>
                      <p class="text-small mt-3">
                        {{
                          "Helping established brands to become more recognizable, that’s either through creating new and additional brand assets, or creating and managing brand awareness campaigns."
                            | limitTo(70)
                        }}
                      </p>
                      <div class="columns is-vcentered mt-2">
                        <!-- <div class="column">
                        <p class="text-small">Starting from</p>
                        <p class="text-medium">
                          {{ service.price | currency }}
                        </p>
                      </div> -->
                        <div class="column is-narrow">
                          <b-button type="is-info" size="is-small">
                            Select
                          </b-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
            <hr class="my-5" />
          </div>

          <div v-if="allServices && !loading" class="pt-5">
            <p class="is-subtitle-small is-dark-gray is-bold my-5">
              More Services
            </p>

            <div class="columns my-5 is-multiline">
              <div
                class="column is-6-tablet is-3-desktop"
                v-for="service in allServices"
                :key="service._id"
              >
                <router-link
                  :to="{
                    name: 'OrderQuestionnaire',
                    query: { id: service._id },
                  }"
                >
                  <div class="box hover has-pointer service-card">
                    <ServiceIllustrations
                      :service="service.name"
                      :isCard="true"
                    />
                    <p class="is-subtitle-small is-secondary is-bold">
                      {{ service.name }}
                    </p>
                    <p class="text-small mt-3">
                      {{ service.description | limitTo(70) }}
                    </p>
                    <div class="columns is-vcentered mt-2">
                      <!-- <div class="column">
                        <p class="text-small">Starting from</p>
                        <p class="text-medium">
                          {{ service.price | currency }}
                        </p>
                      </div> -->
                      <div class="column is-narrow">
                        <b-button type="is-info" size="is-small">
                          Select
                        </b-button>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
              <div class="column is-6-tablet is-3-desktop">
                <router-link
                  :to="{
                    name: 'CustomizedService',
                  }"
                >
                  <div class="box hover has-pointer service-card">
                    <ServiceIllustrations
                      :service="'Customized Service'"
                      :isCard="true"
                    />
                    <p class="is-subtitle-small is-bold is-secondary">
                      Customized Service
                    </p>
                    <p class="text-small mt-3">
                      {{
                        "Helping established brands to become more recognizable, that’s either through creating new and additional brand assets, or creating and managing brand awareness campaigns."
                          | limitTo(70)
                      }}
                    </p>
                    <div class="columns is-vcentered mt-2">
                      <!-- <div class="column">
                        <p class="text-small">Starting from</p>
                        <p class="text-medium">
                          {{ service.price | currency }}
                        </p>
                      </div> -->
                      <div class="column is-narrow">
                        <b-button type="is-info" size="is-small">
                          Select
                        </b-button>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modals Start -->
    <b-modal v-model="showPendingPaymentModal" has-modal-card>
      <div class="modal-card">
        <section class="modal-card-body pt-5 has-text-centered">
          <div class="columns is-vcentered">
            <div class="column">
              <p class="modal-card-title my-5">Processing your payment</p>
              <img
                src="@/assets/img/misc/Payment_processing_illustration.svg"
              />
              <p>
                Once your payment is processed, we will ask you few questions
                regarding your business for our experts to start working on it.
              </p>
              <p class="mt-3">
                Don't worry, we will send an email notification once it's
                processed.
              </p>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <b-button
            label="Close"
            @click="
              () => {
                this.showPendingPaymentModal = false;
              }
            "
          />
        </footer>
      </div>
    </b-modal>
    <!-- Modals End -->
  </div>
</template>

<script>
import Loading from "../../components/Loading.vue";
import ServiceIllustrations from "../../components/misc/ServiceIllustrations.vue";
import { filter } from "lodash";

export default {
  name: "Dashboard",
  components: { Loading, ServiceIllustrations },
  data() {
    return {
      loading: false,
      showPendingPaymentModal: false,
    };
  },
  async created() {
    this.loading = true;
    await this.$store.dispatch("getAllServices");
    await this.$store.dispatch("getAllProducts");
    await this.$store.dispatch("getAllCustomerOrders");
    this.loading = false;
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
    allServices() {
      return undefined;
    },
    allProducts() {
      return this.$store.state.allProducts
        ? filter(this.$store.state.allProducts, (product) => {
            return product.service.length == 1;
          })
        : undefined;
    },
    allBundles() {
      return this.$store.state.allProducts
        ? filter(this.$store.state.allProducts, (product) => {
            return product.service.length > 1;
          })
        : undefined;
    },
    activeOrders() {
      return this.$store.state.allCustomerOrders
        ? filter(this.$store.state.allCustomerOrders, (item) => {
            return item.status != "completed";
          })
        : undefined;
    },
    completedOrders() {
      return this.$store.state.allCustomerOrders
        ? filter(this.$store.state.allCustomerOrders, { status: "completed" })
        : undefined;
    },
  },
  methods: {
    getActiveOrderLink(activeOrder) {
      if (activeOrder.status == "processingPayment") {
        return {};
      } else if (activeOrder.status == "submitQuestionnaire") {
        return {
          name: "OrderQuestionnaire",
          query: {
            orderId: activeOrder._id,
            serviceId: activeOrder.service._id,
          },
        };
      } else {
        return {
          name: "OrderDetail",
          query: {
            orderId: activeOrder._id,
            serviceId: activeOrder.service._id,
          },
        };
      }
    },
    getStatusIcon(status) {
      if (status == "needsAttention") {
        return "is-danger";
      } else if (status == "inProgress") {
        return "is-info";
      } else if (status == "processingPayment") {
        return "is-info";
      } else if (status == "submitQuestionnaire") {
        return "is-info";
      } else if (status == "newUpdate") {
        return "is-info";
      } else if (status == "completed") {
        return "is-info";
      } else {
        return "is-light";
      }
    },
    getProjectTagStyle(bgColor) {
      // calculating text color to be black or white depending on the background color
      var color = bgColor.charAt(0) === "#" ? bgColor.substring(1, 7) : bgColor;
      var r = parseInt(color.substring(0, 2), 16); // hexToR
      var g = parseInt(color.substring(2, 4), 16); // hexToG
      var b = parseInt(color.substring(4, 6), 16); // hexToB
      const textColor =
        r * 0.299 + g * 0.587 + b * 0.114 > 150 ? "#000000" : "#FFFFFF";

      const style = bgColor
        ? "background-color: " +
          bgColor +
          " !important; color: " +
          textColor +
          " !important"
        : "";

      return style;
    },
    getStatusTagColor(status) {
      if (status == "needsAttention") {
        return "is-danger";
      } else if (status == "inProgress") {
        return "is-info";
      } else if (status == "newUpdate") {
        return "is-info";
      } else if (status == "completed") {
        return "is-info";
      } else if (status == "processingPayment") {
        return "is-info";
      } else if (status == "submitQuestionnaire") {
        return "is-info";
      } else {
        return "is-light";
      }
    },
    getStatusClass(status) {
      if (status == "needsAttention") {
        return "has-text-danger";
      } else if (status == "inProgress") {
        return "has-text-info";
      } else if (status == "newUpdate") {
        return "has-text-success";
      } else if (status == "completed") {
        return "has-text-success";
      } else if (status == "processingPayment") {
        return "has-text-info";
      } else if (status == "submitQuestionnaire") {
        return "has-text-success";
      } else {
        return "has-text-dark";
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
</style>
