var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-body",attrs:{"id":"app"}},[_c('div',{staticClass:"p-3"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('div',{staticClass:"spacer-small"}),_c('div',{staticClass:"columns is-vcentered"},[_c('div',{staticClass:"column pb-5"},[_c('p',{staticClass:"is-subtitle is-secondary is-bold"},[_vm._v("Project Workspace")]),(_vm.currentUser)?_c('p',{staticClass:"mt-5 is-bold"},[_vm._v(" Welcome "+_vm._s(_vm.currentUser.fullName)+" ")]):_vm._e(),_c('p',{staticClass:"mt-2"},[_vm._v(" Access the professional services you need to build your brand ")])]),_c('div',{staticClass:"column mt-5 is-3"},[_c('img',{attrs:{"src":require("@/assets/img/brandeeq_method_illustration.svg")}}),_c('br'),_c('router-link',{attrs:{"to":{ name: 'BuildBrands' }}},[_c('b-button',{staticClass:"mt-5 is-button-secondary",attrs:{"type":"is-info","size":"is-small"}},[_vm._v(" Learn more ")])],1)],1)]),_c('div',{staticClass:"spacer-small"}),(_vm.loading)?_c('div',[_c('Loading')],1):_vm._e(),(_vm.activeOrders && _vm.activeOrders.length && !_vm.loading)?_c('div',[_c('p',{staticClass:"is-subtitle-small is-dark-gray is-bold my-5"},[_vm._v(" Active Services ")]),_c('div',{staticClass:"columns my-5 is-multiline"},_vm._l((_vm.activeOrders),function(activeOrder){return _c('div',{key:activeOrder._id,staticClass:"column is-6-tablet is-3-desktop mt-5",on:{"click":function () {
                  if (activeOrder.status == 'processingPayment') {
                    _vm.showPendingPaymentModal = true;
                  }
                }}},[_c('router-link',{attrs:{"to":_vm.getActiveOrderLink(activeOrder)}},[_c('div',{staticClass:"box hover has-pointer p-0"},[(false)?_c('div',{staticClass:"has-text-right",staticStyle:{"margin-top":"-40px","margin-right":"-40px"}},[_c('b-tag',{staticClass:"number-tag is-bold",attrs:{"size":"is-large","type":_vm.getStatusTagColor(activeOrder.status),"rounded":""}},[_c('i',{staticClass:"mdi mdi-bell"})])],1):_vm._e(),_c('ServiceIllustrations',{attrs:{"service":activeOrder.service.name,"isCard":true}}),_c('div',{staticClass:"px-4 pt-2 pb-4"},[_c('div',{staticClass:"has-text-right",staticStyle:{"margin-top":"-28px !important"}},[(activeOrder.projectName)?_c('b-tag',{staticClass:"mb-2",style:(activeOrder.projectColor
                            ? _vm.getProjectTagStyle(activeOrder.projectColor)
                            : ''),attrs:{"type":"is-warning","size":"is-small"}},[_vm._v(" "+_vm._s(activeOrder.projectName || "Uncategorized")+" ")]):_c('div',{staticStyle:{"min-height":"30px"}})],1),_c('p',{staticClass:"is-subtitle-small is-secondary is-bold"},[_vm._v(" "+_vm._s(activeOrder.service.name)+" ")]),_c('p',{class:'' + _vm.getStatusClass(activeOrder.status)},[_vm._v(" "+_vm._s(_vm._f("status")(activeOrder.status,"customer"))+" ")])])],1)])],1)}),0),_c('hr',{staticClass:"my-5"})]):_vm._e(),(_vm.completedOrders && _vm.completedOrders.length && !_vm.loading)?_c('div',[_c('p',{staticClass:"is-subtitle-small is-dark-gray is-bold my-5"},[_vm._v(" Completed Services ")]),_c('div',{staticClass:"columns my-5 is-multiline"},_vm._l((_vm.completedOrders),function(completedOrder){return _c('div',{key:completedOrder._id,staticClass:"column is-6-tablet is-3-desktop mt-5"},[_c('router-link',{attrs:{"to":{
                  name: 'OrderDetail',
                  query: { id: completedOrder._id },
                }}},[_c('div',{staticClass:"box hover has-pointer p-0"},[(false)?_c('div',{staticClass:"has-text-right",staticStyle:{"margin-top":"-40px","margin-right":"-40px"}},[_c('b-tag',{staticClass:"number-tag is-bold",attrs:{"size":"is-large","type":_vm.getStatusTagColor(completedOrder.status),"rounded":""}},[_c('i',{staticClass:"mdi mdi-bell"})])],1):_vm._e(),_c('ServiceIllustrations',{attrs:{"service":completedOrder.service.name,"isCard":true}}),_c('div',{staticClass:"px-4 pt-2 pb-4"},[_c('div',{staticClass:"has-text-right",staticStyle:{"margin-top":"-28px !important"}},[(completedOrder.projectName)?_c('b-tag',{staticClass:"mb-2",style:(completedOrder.projectColor
                            ? _vm.getProjectTagStyle(completedOrder.projectColor)
                            : ''),attrs:{"type":"is-warning","size":"is-small"}},[_vm._v(" "+_vm._s(completedOrder.projectName || "Uncategorized")+" ")]):_c('div',{staticStyle:{"min-height":"30px"}})],1),_c('p',{staticClass:"is-subtitle-small is-secondary is-bold"},[_vm._v(" "+_vm._s(completedOrder.service.name)+" ")]),_c('p',{class:'' + _vm.getStatusClass(completedOrder.status)},[_vm._v(" "+_vm._s(_vm._f("status")(completedOrder.status,"customer"))+" ")])])],1)])],1)}),0),_c('hr',{staticClass:"my-5"})]):_vm._e(),(_vm.allBundles && !_vm.loading)?_c('div',{staticClass:"pt-5"},[_c('p',{staticClass:"is-subtitle-small is-dark-gray is-bold my-5"},[_vm._v(" Discounted Bundles ")]),_c('div',{staticClass:"columns my-5 is-multiline"},_vm._l((_vm.allBundles),function(product){return _c('div',{key:product._id,staticClass:"column is-6-tablet is-4-desktop"},[_c('router-link',{attrs:{"to":{
                  name: 'ProductDetail',
                  query: { id: product._id },
                }}},[_c('div',{staticClass:"hover has-pointer p-0 m-0"},[_c('ServiceIllustrations',{attrs:{"service":product.name,"isCard":true}})],1)])],1)}),0),_c('hr',{staticClass:"my-5"})]):_vm._e(),(_vm.allProducts && !_vm.loading)?_c('div',{staticClass:"pt-5"},[_c('p',{staticClass:"is-subtitle-small is-dark-gray is-bold my-5"},[_vm._v(" More Services ")]),_c('div',{staticClass:"columns my-5 is-multiline"},[_vm._l((_vm.allProducts),function(product){return _c('div',{key:product._id,staticClass:"column is-6-tablet is-3-desktop"},[_c('router-link',{attrs:{"to":{
                  name: 'ProductDetail',
                  query: { id: product._id },
                }}},[_c('div',{staticClass:"box hover has-pointer service-card p-0"},[_c('ServiceIllustrations',{attrs:{"service":product.name,"isCard":true}}),_c('div',{staticClass:"px-4 pt-2 pb-4"},[_c('p',{staticClass:"is-subtitle-small is-secondary is-bold"},[_vm._v(" "+_vm._s(product.name)+" ")]),_c('p',{staticClass:"text-small mt-3"},[_vm._v(" "+_vm._s(_vm._f("limitTo")(product.description,70))+" ")]),_c('div',{staticClass:"columns is-vcentered mt-2"},[_c('div',{staticClass:"column is-narrow"},[_c('b-button',{attrs:{"type":"is-info","size":"is-small"}},[_vm._v(" Select ")])],1)])])],1)])],1)}),_c('div',{staticClass:"column is-6-tablet is-3-desktop"},[_c('router-link',{attrs:{"to":{
                  name: 'CustomizedService',
                }}},[_c('div',{staticClass:"box hover has-pointer service-card p-0"},[_c('ServiceIllustrations',{attrs:{"service":'Customized Service',"isCard":true}}),_c('div',{staticClass:"px-4 pt-2 pb-4"},[_c('p',{staticClass:"is-subtitle-small is-bold is-secondary"},[_vm._v(" Customized Service ")]),_c('p',{staticClass:"text-small mt-3"},[_vm._v(" "+_vm._s(_vm._f("limitTo")("Helping established brands to become more recognizable, that’s either through creating new and additional brand assets, or creating and managing brand awareness campaigns.",70))+" ")]),_c('div',{staticClass:"columns is-vcentered mt-2"},[_c('div',{staticClass:"column is-narrow"},[_c('b-button',{attrs:{"type":"is-info","size":"is-small"}},[_vm._v(" Select ")])],1)])])],1)])],1)],2),_c('hr',{staticClass:"my-5"})]):_vm._e(),(_vm.allServices && !_vm.loading)?_c('div',{staticClass:"pt-5"},[_c('p',{staticClass:"is-subtitle-small is-dark-gray is-bold my-5"},[_vm._v(" More Services ")]),_c('div',{staticClass:"columns my-5 is-multiline"},[_vm._l((_vm.allServices),function(service){return _c('div',{key:service._id,staticClass:"column is-6-tablet is-3-desktop"},[_c('router-link',{attrs:{"to":{
                  name: 'OrderQuestionnaire',
                  query: { id: service._id },
                }}},[_c('div',{staticClass:"box hover has-pointer service-card"},[_c('ServiceIllustrations',{attrs:{"service":service.name,"isCard":true}}),_c('p',{staticClass:"is-subtitle-small is-secondary is-bold"},[_vm._v(" "+_vm._s(service.name)+" ")]),_c('p',{staticClass:"text-small mt-3"},[_vm._v(" "+_vm._s(_vm._f("limitTo")(service.description,70))+" ")]),_c('div',{staticClass:"columns is-vcentered mt-2"},[_c('div',{staticClass:"column is-narrow"},[_c('b-button',{attrs:{"type":"is-info","size":"is-small"}},[_vm._v(" Select ")])],1)])],1)])],1)}),_c('div',{staticClass:"column is-6-tablet is-3-desktop"},[_c('router-link',{attrs:{"to":{
                  name: 'CustomizedService',
                }}},[_c('div',{staticClass:"box hover has-pointer service-card"},[_c('ServiceIllustrations',{attrs:{"service":'Customized Service',"isCard":true}}),_c('p',{staticClass:"is-subtitle-small is-bold is-secondary"},[_vm._v(" Customized Service ")]),_c('p',{staticClass:"text-small mt-3"},[_vm._v(" "+_vm._s(_vm._f("limitTo")("Helping established brands to become more recognizable, that’s either through creating new and additional brand assets, or creating and managing brand awareness campaigns.",70))+" ")]),_c('div',{staticClass:"columns is-vcentered mt-2"},[_c('div',{staticClass:"column is-narrow"},[_c('b-button',{attrs:{"type":"is-info","size":"is-small"}},[_vm._v(" Select ")])],1)])],1)])],1)],2)]):_vm._e()])])]),_c('b-modal',{attrs:{"has-modal-card":""},model:{value:(_vm.showPendingPaymentModal),callback:function ($$v) {_vm.showPendingPaymentModal=$$v},expression:"showPendingPaymentModal"}},[_c('div',{staticClass:"modal-card"},[_c('section',{staticClass:"modal-card-body pt-5 has-text-centered"},[_c('div',{staticClass:"columns is-vcentered"},[_c('div',{staticClass:"column"},[_c('p',{staticClass:"modal-card-title my-5"},[_vm._v("Processing your payment")]),_c('img',{attrs:{"src":require("@/assets/img/misc/Payment_processing_illustration.svg")}}),_c('p',[_vm._v(" Once your payment is processed, we will ask you few questions regarding your business for our experts to start working on it. ")]),_c('p',{staticClass:"mt-3"},[_vm._v(" Don't worry, we will send an email notification once it's processed. ")])])])]),_c('footer',{staticClass:"modal-card-foot"},[_c('b-button',{attrs:{"label":"Close"},on:{"click":function () {
              this$1.showPendingPaymentModal = false;
            }}})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }